#root,
body {
  height: 100%;
}

:root {
  --whispir-primary: #3935F0;
  --whispir-background: #030414;
  --whispir-accent: #FD8FD6;
  --whispir-border: #FA4E29;
  --whispir-text: #FBF7F7;

  --header-height: 60px;
}

.stoplight-header {
  display: flex;
  height: var(--header-height);
}

.stoplight-lnb {
  width: 300px;
  min-width: 300px;
  padding-left: 3.5em;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  position: fixed;
  height: var(--header-height);

  background-color: var(--whispir-background);
  border-bottom: 1px solid hsla(218, 32%, 78%, 0.5);
  box-sizing: border-box;
}

.stoplight-lnb svg {
  height: 2rem;
  padding-left: 16px;
}

.stoplight-search {
  display: flex;
  flex: auto;
  align-items: center;
  padding-left: 40px;
  padding-right: 16px;
  margin-left: 300px;

  border-bottom: 1px solid hsla(218, 32%, 78%, 0.5);
  background-color: white;
  z-index: 1;
}

.stoplight-search svg {
  height: 12px;
  cursor: text;
}

.stoplight-search input {
  padding-right: 40px;
  padding-left: 32px;
  width: 100%;
}

/* STOPLIGHT CUSTOM STYLES */
/* Removing the "Send API Request" button */
button.sl-button.sl-h-sm.sl-text-base.sl-font-medium.sl-px-1\.5.sl-bg-primary.hover\:sl-bg-primary-dark.active\:sl-bg-primary-darker.disabled\:sl-bg-canvas-100.sl-text-on-primary.disabled\:sl-text-body.sl-rounded.sl-border-transparent.sl-border.disabled\:sl-opacity-70 {
  display: none;
}

/* Adjusting margin of main RHS content */
.sl-overflow-y-auto.sl-flex-1.sl-w-full.sl-px-24.sl-bg-canvas {
  margin-left: calc((100% - 1800px) / 2 + 300px);

  top: var(--header-height);
}

/* Adjusting margin of main RHS content at smaller screen size */
@media only screen and (max-width: 1800px) {
  .sl-overflow-y-auto.sl-flex-1.sl-w-full.sl-px-24.sl-bg-canvas {
    margin-left: 300px;
  }
  .stoplight-search {
    margin-left: 300px;
  }
}

/* Adjusting position and color of Table of Contents */
.sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky.sl-inset-y-0.sl-pt-8.sl-bg-canvas-100.sl-border-r {
  position: fixed;
  top: var(--header-height);
  background-color: var(--whispir-background);
  padding-top: 0px;
  padding-left: 3.8em !important;
}

/* Coloring the Table of Contents background and text */
.sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky.sl-inset-y-0.sl-pt-8.sl-bg-canvas-100.sl-border-r .sl-bg-canvas-100 {
  color: var(--whispir-text);
  background-color: var(--whispir-background);
}

/* Coloring the Table of Contents arrows */
.sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky.sl-inset-y-0.sl-pt-8.sl-bg-canvas-100.sl-border-r .sl-text-muted {
  color: var(--whispir-text);
}

/* Coloring the Tabe of Contents active state */
.sl-bg-primary-tint {
  background-color: var(--whispir-primary);
}

/* Coloring the Tabe of Contents hover state */
.hover\:sl-bg-canvas-200:hover {
  background-color: var(--whispir-primary);
}

/* STOPLIGHT CUSTOM STYLES */
